import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React, { useState } from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
export default function Blog({ data }) {
  const images = {
    blog: getImage(convertToBgImage(data.blog)),
  }

  const [active, setActive] = useState(0)

  const blogs = [
    {
      title: "Simplifying IT Deployments",
      design: "",
      by: "By: Madhusha Dehigaspitiya - Business Development Manager",
      date: "06th October 2024",

      para1: "What is SmartRow™?",
      para2:
        "Vertiv’s SmartRow™ is a self-contained, **modular data centre that simplifies** IT deployments in indoor spaces. It combines several critical components into a single, integrated solution.",
      para3:
        "Cabinets: These house servers, networking equipment, and other IT infrastructure.",
      para4:
        "Closed Aisle Cooling System: Efficient cooling ensures optimal performance and reliability.",
      para5:
        "Power Distribution: Integrated power distribution units (PDUs) manage electricity delivery.",
      para6:
        "Centralised Infrastructure Monitoring System: Real-time monitoring enhances visibility and control.",
      para7: "Key Benefits for Businesses",
      para9: "Space Optimization",
      para10:
        "The SmartRow™ optimises space utilisation by integrating all essential components within a compact footprint. It significantly reduces the need for large server rooms or data centres.",
      para11:
        "Without major structural modifications, businesses can deploy the SmartRow™ in existing office spaces, warehouses, or other indoor environments.",
      para12: "Cost Efficiency",
      para13:
        "Traditional data centres involve substantial upfront costs for construction, cooling systems, and power infrastructure. The SmartRow™ streamlines these expenses.",
      para14:
        "OPEX (Operating Expenditure) Savings: Efficient cooling, centralised monitoring, and streamlined maintenance lead to ongoing cost savings.",
      para15: "Reliability and Redundancy",
      para16:
        "The integrated UPS (Uninterruptible Power Supply) system ensures continuous power availability, reducing the risk of downtime.",
      para17:
        "N+1 redundancy design provides fault tolerance, ensuring business continuity even during equipment failures.",
      para18: "Rapid Deployment",
      para19:
        "Unlike traditional data centres that take months to build, the SmartRow™ can be deployed within weeks.",
      para20:
        "Businesses can quickly scale up their IT infrastructure to meet growing demands.",
      para21: "Enhanced IT Control",
      para22:
        "Centralised monitoring allows IT teams to proactively address issues, optimise performance, and proactively prevent disruptions.",
      para23:
        "The SmartRow™ empowers businesses with greater control over their critical systems.",
      para24: "Real-World Impact",
      para25:
        "Imagine a small-to-medium-sized business expanding its operations. Instead of constructing a dedicated data centre, they choose the SmartRow™.",
      para26:
        "The result? A cost-effective, space-efficient solution that seamlessly integrates IT infrastructure enhances reliability and accelerates growth.",
      para27:
        "Vertiv’s SmartRow™ is a game-changer for businesses seeking agility, efficiency, and reliability in their IT operations. Whether it’s a startup, a branch office, or an enterprise, the SmartRow™ paves the way for smoother business processes and future scalability.",
    },
    {
      title: "Rise with SAP: Your Business Catalyst in the Digital Realm",
      by: "Nimna Williams, Business Development Manager, ERP Team, Tech Pacific Lanka",
      date: "05th March 2024",
      para1:
        "In the fast-paced world of business evolution, 'Rise with SAP' is not just a tool; it's a game-changer. Let's dive into the nitty-gritty of how this transformative initiative can genuinely propel your business forward and, more importantly, how it's not a one-size-fits-all but rather a tailor-made suit for specific business personas.",
      para2:
        "Empowering the Trailblazers: For the trailblazers and trendsetters in industries like technology, 'Rise with SAP' acts as a compass. It doesn't just automate; it elevates innovation. Imagine a world where your brilliant ideas seamlessly translate into operational reality without the fuss. That's the promise here.",
      para3:
        "Fueling Growth for Mid-Market Marvels: Mid-sized enterprises, often the unsung heroes, find in 'Rise with SAP' a growth accelerator. It's not about drowning in data; it's about sifting through it effortlessly. From intelligent analytics to adaptable scalability, it's about growing without growing pains.",
      para4:
        "Navigating Complexity for Niche Players: With their unique demands, Niche players need a companion, not just a tool. 'Rise with SAP' doesn't shy away from complexity; it thrives in it. Tailored solutions for intricate processes and industry-specific nuances – that's the promise for businesses that dance to their rhythm.",
      para5:
        "Unleashing Potential for Global Giants: For the global giants steering industries, 'Rise with SAP' is not just any ERP; it's a strategic partner. Think seamless collaboration, real-time insights, and a robust cloud-based backbone that doesn't crack under the weight of global operations. It's about global reach without the global headache.",
      para6:
        "Enhancing Precision for Manufacturing Maestros: In manufacturing, precision is not a luxury; it's a necessity. 'Rise with SAP' understands the heartbeat of manufacturing businesses. It's about more than streamlined processes; it's about precision unleashed. From procurement to production, it's the conductor orchestrating an intricate symphony.",
      para7:
        "Elevating Experience for Customer-Centric Brands: For businesses putting customers at the heart of everything, 'Rise with SAP' is your secret sauce. It's not just about managing customer relationships; it's about creating memorable experiences. Picture a world where your brand anticipates needs and exceeds expectations. That's the reality it paints.",
      para8:
        "Uniting Operations for Complex Supply Chains: Supply chains are the lifelines of many businesses. 'Rise with SAP' doesn't just manage supply chains; it unites them. It's about transparency, resilience, and adaptability in the face of disruptions. It's the glue holding together what can be chaotic but doesn't have to be.",
      para9:
        "Simplifying Financials for Financial Wizards: For financial wizards juggling numbers, 'Rise with SAP' is your magic wand. It's not just about keeping the books; it's about financials simplified. From comprehensive reporting to real-time analytics, the financial compass navigates you through the numbers jungle.",
      para10:
        "In essence, 'Rise with SAP' isn't a jack-of-all-trades; it's a master of tailored solutions. It's not just about implementing; it's about transforming. Whether you're a handy player or an industry giant, 'Rise with SAP' is not just a tool in your hand; it's your ally in the digital frontier.",
    },
    {
      title: "Revolutionising Design with Adobe's Generative AI",
      by: "",
      date: "05th October 2023",
      para1:
        "In a world where design is not just a profession but an ever-evolving language that communicates ideas, emotions, and stories, staying at the forefront demands embracing innovation. Enter Adobe's latest breakthrough – Generative AI.",
      para2:
        "This groundbreaking technology promises to redefine the design game, unleashing a wave of creativity and possibilities that will forever transform how we approach design.",
      para3:
        "Imagine a tool that could effortlessly interpret your creative vision and produce various design concepts tailored to your ideas. This is where Adobe's Generative AI steps in. This revolutionary technology is more than just an application; it's an ingenious collaborator that co-creates with you. It understands and amplifies your intent, producing design variations that resonate with your artistic style. This symbiotic relationship between human creativity and artificial intelligence opens up new dimensions of creativity.",
      para4:
        "In design, collaboration is often a pivotal catalyst for innovation. Now, envision collaborating with an AI that understands your creative nuances and offers novel insights to refine your concepts. Adobe's Generative AI is the ultimate partner in this creative journey",
      para5:
        "Are you worried that AI might overshadow your creative identity? Fear not. Adobe's Generative AI respects your style and preferences. Its suggestions are adaptable, aligning with your artistic voice. Think of it as an AI-powered design assistant – one that understands you intimately and supports you in producing work that's uniquely yours.",
      para6:
        "Every designer, at some point, faces creative blocks. Adobe's Generative AI acts as a catalyst in these moments. Its imaginative suggestions inject fresh perspectives into your work, sparking renewed inspiration. Think of it as your creative cheerleader, encouraging you to explore uncharted territories in your designs.",
      para7:
        "Adobe's Generative AI is more than just a glimpse into the future; it's a leap. It's the intersection of human intuition and AI's analytical prowess, transforming design from mundane to extraordinary. It empowers seasoned designers and newcomers, offering a gateway to a realm where innovation knows no limits.",
      para8:
        "Ready to transcend the ordinary and dive into a world of limitless design possibilities? Adobe's Generative AI offers the passport. It's your portal to a universe where creativity reigns supreme, collaboration is redefined, and efficiency is elevated. The future of design is here, and it's waiting for you to embrace it.",
      para9:
        "As you embark on this exciting path of design evolution, why not ensure you have the best tools? Adobe, a name synonymous with innovation and creativity, offers solutions that amplify your artistic potential. If you're ready to embrace the future of design, consider acquiring Adobe products through Tech Pacific Lanka. As a reliable partner, Tech Pacific Lanka provides the software, expertise, and support to maximise your creative endeavours.",
      para10:
        "Take that step today – explore Adobe's Generative AI and sharpen your design game. Unlock possibilities you've never dreamed of and create in ways that inspire awe. Your creative journey is waiting, and Tech Pacific Lanka is here to accompany you every step of the way. Bring your imagination to life with Adobe and Tech Pacific Lanka.",
    },
    {
      title: "Safeguarding Your Business Data: The Power of Arcserve",
      design: "type1",
      by: "",
      date: "06th September 2023",

      para1: "The Dark Side of Unprotected Data",
      para2:
        "Imagine your business data as a vault of precious treasures. Now, imagine that vault without any locks. Data breaches, cyberattacks, and unexpected disasters could wipe out your business. Financial loss, reputation damage, legal consequences – the list goes on. From sophisticated hackers to unpredictable accidents, the threats are relentless.",
      para3: "The Unsettling Risks of Ignoring Protection ",
      para4:
        "Financial Repercussions: A single data breach can result in millions spent on recovery, compensation, and regulatory penalties. The financial burden can be devastating, particularly for SMEs.",
      para5:
        "Reputational Damage: Trust takes years to build but seconds to shatter. A breach tarnishes your reputation and erodes client confidence, leading to a downward spiral.",
      para6:
        "Operational Disruptions: Data loss means operational downtime. Can your business afford to grind to a halt while you scramble to recover?",
      para7:
        "Legal Complications: Data privacy laws are becoming more stringent. Ignoring protection could land you in a legal quagmire that's hard to escape.",
      para9: "Enter Arcserve: Your Digital Guardian",
      para10:
        "As fear of the risks settles in, here comes the ray of hope – Arcserve. It's not just data protection; it's a fortress for your business continuity. Here's why Arcserve is your ultimate solution:",
      para11:
        "Comprehensive Protection: Arcserve guards your data like an impregnable shield. From cyber threats to hardware failures, it's an all-in-one solution that ensures your data's safety from all angles.",
      para12:
        "Rapid Recovery: Accidents happen, but with Arcserve, they don't have to be disastrous. Quick and efficient recovery means minimal downtime and disruption.",
      para13:
        "Regulatory Compliance: Arcserve understands the legal labyrinth. It keeps you compliant with data privacy laws, saving you from legal jeopardy.",
      para14:
        "Scalable Confidence: As your business grows, so does your data. Arcserve scales with you, adapting to your evolving protection needs seamlessly.",
      para15:
        "Peace of Mind: Knowing your data is secure allows you to focus on what truly matters – growing your business. ",
      para16: "Embrace Arcserve, Embrace a Secure Future",
      para17:
        "The digital landscape is both a goldmine and a minefield. Ignoring protection is a gamble no business can afford. With Arcserve by your side, you're not just safeguarding data but ensuring your business's survival. Don't wait for disaster to strike – fortify your digital realm with Arcserve and pave the way for a prosperous, secure future.",
      para18: "At Tech Pacific Lanka, We Are Your Partner for Protection",
      para19:
        "At Tech Pacific Lanka, we offer more than software; we extend a lifeline to your business. Choosing Arcserve is not just about selecting a solution; it's a decisive move towards robust data security and uninterrupted business operations.",
      para20:
        "Reach out to us today, and together, let's explore the benefits of Arcserve for your organisation. Protect your data, fortify your business, and stride into the digital realm with unwavering confidence. Your data's security is our commitment – let's make your business's future secure, resilient, and thriving.",
    },
    {
      title: "Tech Pacific Lanka: The Ideal Partner for Vertiv Solutions",
      by: "By - Madusha Dehigaspitiya, Senior Business Development Manager, Tech Pacific Lanka",
      date: "05th July 2023",

      para1:
        "As the Senior Business Development Manager, with over eight years of experience working with the company, I am pleased to share my experience with a modular data centre that surpasses its competitors - Tech Pacific Lanka.",
      para2:
        "After a decade of experience delivering optimal and proven digital technology solutions, Tech Pacific Lanka has established a strong partnership with Vertiv, a global giant specialising in deep digital engineering.",
      para3:
        "Tech Pacific Lanka offers a range of Vertiv solutions to cater to your needs. Our range includes Smart Cabinets, Smart Rows, Annual Maintenance Contracts for Smart Solutions and Server Rooms, Greenfield and Brownfield Conventional Data Centers, as well as Monitoring and Management of Existing Data Centers and Server Rooms.",
      para4:
        "Tech Pacific Lanka's Data Center Solutions division has a team of highly skilled experts with over a decade of experience in delivering optimal and proven digital technology solutions. Our team includes certified Vertiv Smart Solutions engineers who have completed their training in Mumbai, India. With a combined experience of more than 30 years, our team has completed 18 projects in Sri Lanka and the Maldives, making us highly experienced and qualified. Our expertise and experience enable us to deliver excellent results, making us a valuable asset to our clients. ",
      para5:
        "At Tech Pacific Lanka, we are committed to providing our clients with the best possible digital technology solutions.",
      para6:
        "Tech Pacific Lanka consistently prioritises client requirements and is flexible in its solution approach. We opt to avoid compromises on quality while ensuring peak customer satisfaction. Our superior technical proficiency and highly skilled teams maintain a proven track record across industries, offering long-term care for all Vertiv installations.",
      para7:
        "We go one step further by forming maintenance contracts after the standard one-year product warranty period, ensuring prolonged responsibility. It is worth noting that the first cabinet traded in 2015 is still under our care, a lengthy relationship that stands testament to the company's commitment and principles.",
      para8:
        "When finding a partner for Vertiv solutions, look no further than Tech Pacific Lanka. Our highly skilled team of experts, proven track record, and commitment to quality make us the ideal choice for anyone seeking efficient and reliable solutions.",
    },
    {
      title: "The consequences of pirated software ",
      date: "02nd February 2023",

      para1:
        "The digital age has made it easier and faster to duplicate things than ever before. As a result of this, many individuals and businesses use unauthorised copies of applications like Adobe, either knowingly or unknowingly. While using a pirated version might seem like a viable option, it has a plethora of drawbacks that prevent you from getting the most out of the applications and consequences that can adversely affect your business. ",
      para2:
        "Using pirated software increases the risk of installing malware on your computers. Pirated files often contain viruses and spyware that slow down your systems or, in the worst-case scenario, cause them to stop working altogether. This results in not only a loss of time and potential business but also money, as you would have to pay to get the problem fixed. Certain malware also targets the personal data of staff or customers. If that data is compromised it could lead to possible legal ramifications, along with loss of trust. ",
      para3:
        " Another drawback of pirated software is the inability to access future updates and features. Illegitimate software stagnates in quality, but authorized copies continuously improve. The low lack of updates for illegally obtained software can directly hurt and hinder a business’s growth and potential. This also poses a security risk as you would not be able to get security patches, which increases your vulnerability to a cyberattack. Adobe updates and fixes its applications regularly. Simply choose a convenient time and click “update” in the dashboard.",
      para4:
        " Pirated software also cannot access online services.Adobe’s online cloud features are extremely important for optimized collaboration, storage and data exchange between applications.A working subscription is required to take full advantage of this range of solutions, and pirated versions of the software cannot offer that.",
      para5:
        " Applications like Adobe are continuously improving their products, adding new features and tightening security constantly. In addition to the moral implications of utilising pirated software, the functional disadvantages you face can be detrimental to your business. Choosing to use official versions of the software is both morally and monetarily the right choice.",
    },
    {
      title: "SAP HANA Cloud’s Key Services & Advantages ",
      date: "30th January 2023",

      para1:
        " SAP HANA Cloud is a fully managed database that incorporates and enhances the SAP HANA platform's already-existing capabilities. As a platform with cutting-edge data and analytics applications, SAP HANA Cloud can combine data from different sources and in various formats. The database is able to integrate with both your on-site infrastructure and cloud systems and can scale to fit your expanding demands.",
      para2:
        " One of the databases’ main advantages is that you are able to deliver transactions and analytics simultaneously without having to duplicate the data. This allows you to construct and run high-performance transactional applications while receiving real-time analytics.",
      para3:
        "The databases’ highly scalable centralized data repository combined with a native disk storage extension can provide flexible storage tiering capabilities to allow an optimal price-performance ratio for any scenario your enterprise might find itself in. ",
      para4:
        "Another benefit SAP HANA Cloud offers is the ability to develop smart applications with embedded advanced machine learning to analyse sensitive data while protecting privacy. These embedded machine learning libraries and capabilities for integrating common data science and machine learning tools are what allow SAP HANA Cloud to facilitate smart applications. ",

      para5:
        " This results in the ability to process vital data at incredible speeds, perform advanced analytics on live transactional data and manage data more efficiently with the databases’ integrated multi-tier storage. This is all possible due to SAP HANA Cloud’s innovative unified multi-model database system. ",

      para6:
        "SAP HANA Cloud will elevate your business’s processes with faster data access and better insights through machine learning techniques and its real-time analysing prowess. The resulting data can be seamlessly fed to your preferred analytics tool, leading to more optimized outputs. It is an innovative tool that can streamline multiple processes, thereby helping your business achieve growth and optimal performance. ",
    },
    {
      title: "Evaluating ERP Systems For Your Enterprise",
      by: "By - Lalindra Galagedera, CEO, Tech Pacific Lanka",
      date: "16th December 2022",

      para1:
        "With the rapid digitization of how we do business, implementing ERP systems to help an enterprise grow is now commonplace. ERP systems optimize processes, help keep up with consumer demands, scale operations and conduct a host of other tasks that streamline and optimize your business model. In this post, I would like to delve into how one would choose which ERP solution is the best fit for their digital transformation and the factors that must be considered to make an educated decision.",
      para2:
        "One of the first factors to understand for these systems is the technical and functional maturity. The scope of evaluating a system’s maturity is not limited to whether it has a certain functionality and capability but encompasses how deep that capability is, how robust it is and how flexible it is. Analysing the system from these perspectives provides a clear understanding of its strengths and weaknesses.",
      para3:
        "Another factor is the scalability of the system. This is dependent on your business and the scope of its digital transformation. For some, scalability means high efficiency, standard processes, common business processes, and repeatable processes. Other businesses define scale as the ability to be flexible.",
      para4:
        "The next factor is the flexibility of the technology. If your business is exponentially growing, rapidly changing, entering new markets, acquiring new customers or even acquiring new companies then flexibility is vital, and the system you choose will need to keep up with you and evolve as your business evolves. Therefore, it is imperative to look at where you value flexibility and which business processes require it. This will allow you to assess the product against those criteria.",
      para5:
        "Another factor to evaluate with ERP systems is the level of functionality they offer. This is completely dependent on your business’s priorities. These priorities might involve requiring strong financials or solid workflows, enterprise performance management, data and analytics. Ensuring you identify your priorities and compare each system’s ability to meet them is important.",
      para6:
        "While analysing these factors, it is also beneficial to try and challenge the systems to find any possible shortcomings or weaknesses. This is done to not only give you more confidence in the final decision you make but allow you to better plan for and understand what the risks of implementation are, how to mitigate these risks and so you can have an accurate implementation plan and budget as well.",
      para7:
        "Understanding the level of maintenance that will be needed for the system is also important. Despite ERP systems being largely deployed in the cloud, they still require technology maintenance and business user maintenance. Conducting maintenance on your ERP system will require specific skills and competencies from your business’s IT department. Calculating the cost to support the system is also an important aspect.",
      para8:
        "ERP systems have become essential in the modern business landscape. The human body is unable to function without a brain, and a business cannot function without a proper ERP system today. But choosing the correct system that meets your requirements is essential. Comparing the systems available in the market using the factors I have discussed will result in you being able to confidently choose a system that will work perfectly for your business’s digital transformation. Making an informed choice is always the best option, and analysing the options through the lens of these criteria will ensure you have no regrets about your decision.",
    },
    {
      title: "Remote Selling in a Post Pandemic Era",
      by: "By - Lalindra Galagedera, CEO, Tech Pacific Lanka ",
      date: "18th July 2022",

      para1:
        "COVID-19 created a tumultuous period for the entire world. Not only were global economies heavily affected, but businesses were forced to transform their operations almost overnight as well. Employees were suddenly having to meet targets and help their companies succeed in a difficult time, all while adapting to a new environment, as the world got used to working from home. ",
      para2:
        "For Sales Associates, this new working climate transformed the way they communicate with clients. Before the pandemic, sales operations consisted of a large amount of travel, to meet potential clients and chase down leads in order to generate new business. Within the office, it also required constant communication, with the rest of the team and other clients.   ",
      para3:
        "While working from home does change what would be considered the norm for sales executives, it also offers a plethora of possibilities that can result in greater success if utilized. Businesses and other potential customers are now more willing to conduct sales conversations over the phone or through other digital means since this type of communication has become the norm. This broadens the list of prospects associates can pursue, as they are no longer held back by distance or time.",
      para4:
        "Working from home also allows one to customize their workspace. Associates can tailor their station to optimize personal comfort. This leads to greater productivity, as employees will be more motivated to work in an environment that makes them feel at ease. Remote work gives everyone a better work-life balance. Transitioning from working to spending time with family or pursuing other interests now takes a few seconds. The daily time taken to commute to and from the office can also be utilized to spend more time with family and friends. The stress from traveling is also greatly lessened. Mornings can now be spent getting a few more minutes of sleep or doing some exercise to kickstart the day. Meals can now be enjoyed fresh and hot, in the comfort of your own home, surrounded by loved ones, instead of in a box or a takeout container. ",
      para5:
        "Remote selling has also been made easier with more companies adopting cloud solutions such as ERP software and other digital infrastructures. Connecting to your business's network and liaising has never been easier, with groundbreaking technology that allows the automation of specific tasks, effective management of the sales pipeline, and organized internal or external communication. Streamlining the remote process will enable sales associates to focus on their tasks instead of worrying about whether their workflow will be affected. ",
      para6:
        "Finally, remote work grants the opportunity to significantly cut down costs. Transport and other areas where one may spend money daily get reduced. The cost of buying office attire is also unnecessary, as remote work allows the freedom to wear what provides you with the most comfort. Working from home requires a stable internet connection and phone plan to meet requirements. ",
      para7:
        "To conclude, while everyone was pushed into adapting quickly to this new norm, there are plenty of advantages that working from home offers. Aside from the one already mentioned, remote work offers employees greater flexibility, as well as an opportunity to empower themselves. Since everyone is working from different locations, associates aren’t supervised constantly. This means that as long as the work is completed on time, any methods employees use to facilitate are adequate. ",
      para8:
        "This also gives people the chance to push themselves, since they have so many new benefits that will enhance their productivity. Taking pride in the business you represent, and constantly making sure to work tirelessly to help it succeed are virtues that will not go unnoticed. Utilizing the multitude of new opportunities to reach one’s potential will be a fulfilling experience, especially in such difficult times. This model of work offers employees so many priceless benefits that improve their daily life.",
      para9:
        "In this new environment, gaining results is paramount, and if everyone commits fully to their role, it helps the entire organization prosper. Taking the initiative and remaining focused on achieving results is vital to success, and those are attributes that must come from within each individual.",
    },

    {
      title: "Diversity and Range of Adobe Applications",
      date: "23rd August 2021",

      para1:
        "Adobe is a brand known across the globe, and this is primarily due to the varied range of software catered to consumers. Tech Pacific offers a spectrum of software by Adobe Inc. and is a licensed Partner for all applications. Adobe Inc. has been a growing multinational computer software company since 1982, and their present offerings are an amalgamation of current tech and user-friendly formatting; making it popular among businesses and individuals alike. The extensive range caters to a broad set of needs and facilitates remarkable outcomes.",
      para2:
        "Adobe’s creative range is possibly the most used software in the world. Adobe Photoshop is a raster graphics editor created in 1988, which is now the industry standard for digital art. The advertising world has long depended on this application for merchandise. Photoshop uses pixels to create images, making it ideal for photograph and image creation as well as editing, however, Adobe Illustrator is a vector-based software that is better suited for graphical illustrations. Adobe Lightroom allows consumers to import, organize and find photos, and it is widely used as a non-destructive photo editing and management tool.",
      para3:
        "Adobe’s motion graphic and video software include Adobe Premiere Pro, a timeline-based video editing application that belongs to Adobe Creative Cloud. It is widely used to edit videos, commercials, and more; used for online, television as well as film. Tech Pacific offers to license for professionals across industries who use Adobe Premiere Pro for digital as well as mass marketing. Adobe After Effects is a tool that allows users to layer videos by combining videos and images seamlessly in one frame, popular among social media marketers and influencers who look to create rapid on-trend content. A highly prevalent software in the Architecture and Design industry would be Adobe Dimension, as it is software that brings design into 3D with remarkable rendering capabilities; functional across macOS and Windows. From a sound-focused point of view, Tech Pacific offers Adobe Audition that is used to create, mix, edit and restore audio content; this includes multitrack, waveform, and spectral display for the best consumer experience.",
      para4:
        "One of the most widely used software in Sri Lanka for web development is Adobe Dreamweaver, it is a proprietary web development tool functional across macOS and Windows operating systems. A more designer-centric software for web development would be Adobe XD, as creatives can focus on user experience and interaction through website features like screen designing, wireframes, and prototypes for websites as well as mobile apps.",
      para5:
        "Due to the extensive editing potential, graphic designers use InDesign as a desktop publishing and typesetting software to create books, e-books, magazines, brochures, and more. Its popularity is understated, as projects created through it can be shared in digital as well as print formats. Adobe Spark is the crown addition to consumer satisfaction and accessibility by Adobe; helping end-users create media online without installing software. Moreover, it has three separate applications to accommodate all creative avenues; Spark Page, Spark Post, and Spark Video.",
      para6:
        "There is a distinct acceleration in digital and remote work due to the Covid-19 pandemic. An application that is enabling authentic virtual transactions and agreements is Adobe Sign; a cloud-based e-signature service that permits users to sign, share and track signature processes using devices. A true game-changer for the many dealings that are impossible to complete without a legitimate signature.",
      para7:
        "Apart from the above, Adobe creative applications such as Premiere Rush, InCopy, Photoshop Camera, Aero, Stock, Photoshop Express, and Flash are all software that assists businesses and creatives to work faster and better; creating quality content through vector graphics, video games, cinematic visual effects and motion graphic, immersive augmented reality experiences across devices.",
      para8:
        "Adobe software is adapting and evolving with technology, and the technical teams behind the wheel are constantly looking for ways to improve all facets of applications while staying true to the core. By opting for licensed adobe software, businesses and individuals avoid the risk of opening up to cybercriminals and legal repercussions. It also ensures timely updates, bug fixes and tech support teams to address concerns around the clock.",
    },

    {
      title:
        "COVID-19 and Lockdowns are Not Barriers to Digital Transformation",
      date: "05th August 2021",

      para1:
        "The global pandemic has put companies of all sizes to the test, cutting across industry and service offering. Many companies have been forced to find new ways of doing business, beyond switching to working from home and hosting virtual meetings.",
      para2:
        "In the field of digital technology, the ‘normal’ on-site implementation of digital solutions was brought to a halt. Companies that had decided on implementing ERP solutions or were engaged mid-process in doing so, found themselves suddenly at a loss.",
      para3:
        "Customers of Tech Pacific however were able to take advantage of our offsite offering to continue the implementation of their SAP ERP solutions virtually. Acorn Travels (SAP ByDesign), Teejay (SAP SuccessFactors), E. B. Creasy (SAP S/4HANA upgrade) and C.W. Mackie (SAP S/4HANA upgrade) are a few of the corporates that availed themselves of the offering and have completed the implementation process.",
      para4:
        "At Tech Pacific, we offer a seamless offsite process through the multiple phases of implementation, from the gathering of requirements to matching needs with adaptations to going live. Across the board, our customers have found the process overwhelmingly positive and were greatly satisfied with the outcomes upon completion.",
      para5:
        "We are currently engaged in one of the region’s biggest implementations of SAP S/4 HANA solution across DIMO companies. Tech Pacific made expert resource personnel available to DIMO by utilizing both the SAP - recommended Activate Methodology as well as Agile Project Management methodology to frame the implementation process. Despite it being a complex implementation that is conducted online, DIMO found the process more productive in some respects than with the on-site process.",
      para6:
        "“There was continuous communication and support ensuring all requirements and agreed upon milestones were met,” said Thushari Steinwell, Vice President - Finance at Acorn Travels. Tech Pacific began implementing SAP ByDesign Cloud ERP for Acorn Travels in August 2020 and completed the process in March this year. By ensuring continuous, clear communication and total transparency, we ensured to meet all of the client’s expectations. The client was also able to save on logistical costs that they would have incurred in the case of an on-site implementation.",
      para7:
        "Steinwell added that she foresaw corporates rethinking on-site implementation in the future with the pandemic having made people more comfortable with a digitized world.",
      para8:
        "Lalith Kulasinghe, Chief Information Officer for Colombo Fort Group Services, which overlooked the SAP S/4HANA version upgrade for E. B. Creasy and C. W. Mackie, said they were unconcerned about an offsite implementation, having worked with Tech Pacific on Sri Lanka’s very first SAP S/4 HANA Enterprise Management implementation in 2016 for the two companies.",
      para9:
        "On our part, we ensured that our clients' needs are met by focusing on customer orientation backed by our team's technical skills and expertise.",
      para10:
        " Offsite implementations are not a phase but an inevitability. In the same manner that video conferencing is now ubiquitous, so is offsite implementation -- here to stay.",
    },

    {
      title: "Digital Transformation through SAP S/4HANA",
      date: "06th July 2021",

      para1:
        "In the twenty first century, some businesses have seen unprecedented growth as a result of digital technology. Enterprise Resource Planning is one key software solution that has helped centralize and digitally transform all facets of a business, and SAP is the frontrunner; almost synonymous with successful ERP. SAP launched SAP S/4HANA in 2015, sought to be the most innovative enterprise platform and the greatest migration from SAP ECC platform to date. ‘HANA’ stands for High-Performance Analytic Appliance, and rightly so, as it has an unmatched capability to work with large volumes of data while retaining operational simplicity.",
      para2:
        "SAP S/4HANA’s business suite provides large companies with the opportunity to deploy transactions while assessing their business’ data real-time, due to the optimization of in-memory databases. With rapid evolution of technology and demands of the newer generation, simplicity and flexibility of both businesses processes and practices are no longer just an option.",
      para3:
        "The diverse range of organizational departments that have embraced SAP S4/HANA makes it a holistic ERP solution for business. SAP S/4HANA assists Asset Management through Resource scheduling, Health and Safety, Plant maintenance and the management of change. In terms of Finance, it primarily incorporates SAP for simplifying monetary operations. Human Resource Management uses SAP S/4HANA to amalgamate the entire gamut of operations including Payroll, Personnel and Event management along with Training and Reporting. When it comes to Manufacturing, SAP S/4HANA employs quality management, production control/planning and production implementation; revolutionizing manufacturing practices by correspondingly sourcing and procuring raw material more efficiently. SAP’s application on Supply Chain has long been a crowning glory to business efficiency, because it plans and maximizes the movement of stock, aligns order generation (POs) with product availability, while managing shipping, stockpiling and delivery; all of which are time sensitive and cost intensive lines of a business backend. When it comes to Sales, SAP S/4HANA is equipped to handle orders, subscription billings and revenue, thereby compacting sales data on a real-time basis. Research and Development take center stage in most established businesses of scale, and SAP S/4HANA facilitates Product Lifecycle Management (PLM), Enterprise Portfolio and Project Management along with Product Safety and Compliance.",
      para4:
        "Tech Pacific has carried out SAP S4/HANA implementations and system updates for some corporate giants in Sri Lanka including Ferentino Tyres, E.B Creasy, Rockland, International Water Management Institute, Lanka SSL and Langwa; refining their business performance across the board. Planning, executing, reporting, and analysis can be done faster than ever before with SAP S/4HANA, enabling decision makers to gather insight from any device at all times, offering peak resilience. A significant difference from SAP ECC would be the simplified structure with easier data access, deeming S/4HANA more attractive to employers. The fully automated data, user friendly and aesthetically trendy interfaces along with real-time insights help business teams maneuver better online. If not for these gains, businesses should look to SAP S/4HANA as the current SAP ECC platform would be obsolete by 2025. Consequently, getting a head start with S/4HANA while reaping the benefits sooner would be a choice of the wise. Being a SAP Gold Partner, Tech Pacific aims to digitally transform businesses through configured solutions, and SAP S/4HANA is the ERP solution for success across industries.",
    },

    {
      title: "Redefining traditional workplaces post the Covid-19 pandemic",
      by: "By - Lalindra Galagedera, CEO, Tech Pacific Lanka",
      date: "02nd June 2021",

      para1:
        " COVID-19 brought about unprecedented human trials, followed by a year of global business challenges and impending recessions. Organizations, including Tech Pacific, rose to the occasion by acting swiftly to safeguard both employees and client interests; amidst prolonged lockdowns and novel safety regulations that altered standard operations. Exactly a year after, as employers around the world experiment with bringing teams back into offices, there is the evident challenge of reinstating former routines; primarily due to employee mindsets and safety. One should perceive the last year as a large-scale global experiment in order to deeply understand the role of offices, and reimagine how work is done.",
      para2:
        " In order to mitigate disruptions instigated by lockdowns, organizations resorted to digital collaborations such as videoconferencing; with results that exceeded expectations. According to research, nearly 50% of employees have confirmed higher productivity rates while 28% have stated they are as productive as they have been while in office. Increased flexibility and reduced commute time allowed better work life balance for employees, while companies accessed pools of talent beyond geographic restraints amidst reduced real estate costs. The natural question remains, is this due to the mere assumption of a temporary situation?",
      para3:
        "There has been a positive shift in outlook when viewing remote working, and due consideration must be given to past processes that yielded these results. Businesses are able to experience this productivity as a result of social capital built up over countless coffees, boardroom meetings and other social engagements that took place prior to the crisis. One cannot ignore relationships built over spontaneous moments of physical interaction and talent development through mentorship.",
      para4:
        "All angles must be evaluated in order to arrive at the best possible way forward, and this would differ from organization to organization. Reconstructing how work is done would ensure that everything that could be done remotely will make a permanent shift; this requires exceptional change-management skills and constant monitoring. Categorizing staff according to working standards would be a sound start for resource reallocation. Fully remote employees would bring about a net positive value creation while hybrid remote employees bring neutral outcome. There would also be employees that should ideally be on site, yet if absolutely necessary can work remotely, thereby resulting in a net negative outcome; they would be hybrid and remote by exception. On-site employees are those who are completely unable to work remotely, i.e. factory floor staff. If classified consequently, businesses will be able to look at vast cost reductions in terms of real estate, transport and Covid-19 associated safety measures, and proceed to allocate significantly lower investments in technology; enabling remote working. If such changes are made with strong alignment to company culture, a resized footprint could be greatly advantageous to businesses.",
      para5:
        " Organizations worldwide could use this moment to dismiss suboptimal old habits and systems, and make a well-planned return to office. Offering flexible weekly work hours that include days for remote work, could uplift the peak work-life balance gained over the past year. Thereby creating a better experience for talent; increasing productivity with the return to office, rather than the reverse. Breaking away from the inertia of the past focusing on reinvention to achieve business objectives in a safer environment with happier employees.",
    },
    {
      title:
        "Sri Lanka’s first SAP S/4HANA Enterprise Management FPS01 Implementation",
      date: "31st May 2021",

      para1:
        "In today’s Success Stories, We share how Tech Pacific achieved Sri Lanka’s very first SAP S/4HANA Enterprise Management FPS01 implementation five years ago, for Colombo Fort Land & Building PLC subsidiaries - E. B. Creasy & Company, C. W. Mackie, and Lanka Special Steels.",
      para2:
        "On September 5th, 2016, the three companies went live on SAP S/4HANA, using business process content supported by industry best practices. Tech Pacific partnered with vCentric Technologies to ensure 12 companies, 22 business verticals and 45 plants/warehouses, accounting for a total of 159 business processes of which 100 were common templated processes across the groups, were included. Together with 95% of SAP best practices adopted, all in a mere six months, the incredible achievement won the project a Gold award from the PMI Colombo Chapter in 2018.",
      para3:
        " The SAP S/4HANA solution combines the digitized core capabilities included in enterprise management with the on-premise solution in SAP portfolio for various lines of business. It allows organizations, no matter how large, to drive end-to-end digitized operations across business functions, streamlining business processes, providing information and insights in real-time and seamlessly integrating the organization with the digital world at large.",
      para4:
        "   Counting over 100 years of operations in Sri Lanka with well-established subsidiaries and associate companies across business domains, Colombo Fort Land & Building PLC's SAP S/4 HANA implementation with Tech Pacific across its three enterprises was the first step in its roadmap to realising a single, scalable business solution across all its companies.",
    },
  ]

  const UnsettlingRisksDataArray = [
    {
      body: "Financial Repercussions:",
      description:
        " A single data breach can result in millions spent on recovery, compensation, and regulatory penalties. The financial burden can be devastating, particularly for SMEs.",
    },

    {
      body: "Reputational Damage:",
      description:
        "Trust takes years to build but seconds to shatter. A breach tarnishes your reputation and erodes client confidence, leading to a downward spiral.",
    },
    {
      body: "Operational Disruptions:",
      description:
        "Data loss means operational downtime. Can your business afford to grind to a halt while you scramble to recover?",
    },

    {
      body: "Legal Complications:",
      description:
        "Data privacy laws are becoming more stringent. Ignoring protection could land you in a legal quagmire that's hard to escape.",
    },
    {
      body: "Legal Complications:",
      description:
        "Data privacy laws are becoming more stringent. Ignoring protection could land you in a legal quagmire that's hard to escape.",
    },
  ]

  const EnterArcservDataArray = [
    {
      body: "Comprehensive Protection:",
      description:
        "Arcserve guards your data like an impregnable shield. From cyber threats to hardware failures, it's an all-in-one solution that ensures your data's safety from all angles.",
    },

    {
      body: "Rapid Recovery:",
      description:
        "Accidents happen, but with Arcserve, they don't have to be disastrous. Quick and efficient recovery means minimal downtime and disruption.",
    },
    {
      body: "Regulatory Compliance:",
      description:
        "Arcserve understands the legal labyrinth. It keeps you compliant with data privacy laws, saving you from legal jeopardy.",
    },

    {
      body: "Scalable Confidence:",
      description:
        "As your business grows, so does your data. Arcserve scales with you, adapting to your evolving protection needs seamlessly",
    },

    {
      body: "Peace of Mind:",
      description:
        "Knowing your data is secure allows you to focus on what truly matters – growing your business.",
    },
  ]

  return (
    <Layout>
      <Seo title="Blog | Tech Pacific Lanka" />
      <section>
        <BackgroundImage {...images.blog}>
          <div className="blog-cover">
            <div className="">
              <div className="container">
                <div className="blog-title">Blogs</div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="blog-main">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="blog-titles-section">
                  {blogs.map((item, index) => {
                    return (
                      <div
                        className="blog-item"
                        onClick={() => {
                          setActive(index)
                        }}
                      >
                        <p className={active === index ? "active" : ""}>
                          {item.title}
                        </p>
                        <span
                          className={active === index ? "active" : "blog-date"}
                        >
                          {item.date}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-md-8">
                <>
                  <div className="blog-article-container">
                    <div className="blog-title">{blogs[active].title}</div>

                    <div className="blog-description-by">
                      {blogs[active].by}
                    </div>

                    <div className="blog-description-date">
                      {blogs[active].date}
                    </div>

                    <div className="blog-content">
                      {blogs[active].design == "type1" ? (
                        <p className="blog-para">{blogs[active].para1}</p>
                      ) : (
                        <p>{blogs[active].para1}</p>
                      )}
                      <p>{blogs[active].para2}</p>
                      {blogs[active].design == "type1" ? (
                        <p className="blog-para">{blogs[active].para3}</p>
                      ) : (
                        <p>{blogs[active].para3}</p>
                      )}

                      {blogs[active].design == "type1" ? (
                        <ul>
                          {UnsettlingRisksDataArray.map(content => (
                            <li>
                              <span className="blog-para">{content.body}</span>{" "}
                              {content.description}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <p>{blogs[active].para4}</p>
                          <p>{blogs[active].para5}</p>
                          <p>{blogs[active].para6}</p>
                          <p>{blogs[active].para7}</p>
                        </>
                      )}

                      <p>{blogs[active].para8}</p>
                      {blogs[active].design == "type1" ? (
                        <p className="blog-para">{blogs[active].para9}</p>
                      ) : (
                        <p>{blogs[active].para9}</p>
                      )}
                      <p>{blogs[active].para10}</p>

                      {blogs[active].design == "type1" ? (
                        <ul>
                          {EnterArcservDataArray.map(content => (
                            <li>
                              <span className="blog-para">{content.body}</span>{" "}
                              {content.description}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <>
                          <p>{blogs[active].para11}</p>
                          <p>{blogs[active].para12}</p>
                          <p>{blogs[active].para13}</p>
                          <p>{blogs[active].para14}</p>
                          <p>{blogs[active].para15}</p>
                        </>
                      )}

                      {blogs[active].design == "type1" ? (
                        <p className="blog-para">{blogs[active].para16}</p>
                      ) : (
                        <p>{blogs[active].para16}</p>
                      )}
                      <p>{blogs[active].para17}</p>
                      {blogs[active].design == "type1" ? (
                        <p className="blog-para">{blogs[active].para1}</p>
                      ) : (
                        <p>{blogs[active].para18}</p>
                      )}
                      <p>{blogs[active].para19}</p>
                      <p>{blogs[active].para20}</p>
                      <p>{blogs[active].para21}</p>
                      <p>{blogs[active].para22}</p>
                      <p>{blogs[active].para23}</p>
                      <p>{blogs[active].para24}</p>
                      <p>{blogs[active].para25}</p>
                      <p>{blogs[active].para26}</p>
                      <p>{blogs[active].para27}</p>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Blogs {
    blog: file(relativePath: { eq: "blog/blog.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
